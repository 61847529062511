
import { useEffect } from 'react';
import Typo from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PetsIcon from '@mui/icons-material/Pets';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import panDoofImg from '../assets/images/pan-doof.png'
import Box from '@mui/system/Box';
const Help = (props) => {

  useEffect(() => {
    document.body.classList.remove('claw-image');
    document.body.classList.add('hearts-image');
  }, []);

  const triggerAlert = () => { 
    alert('Ich liebe dich, Pani!');
  };
  
  return (
    <main>
    <Typo variant="h1">Hilfe für Pani</Typo>
    <Typo variant="h2" sx={{ mb: 5, mt:5 }}>Die App</Typo>
    <Typo>Die App soll Pani daran erinnern, dass Pan sie sehr liebt! Denn Pan ist nicht immer da, um es ihr persönlich zu sagen. 
      In solchen Fällen kann Pani die App nutzen.</Typo>
    
    <Typo variant="h2" sx={{ mt: 5 }}><FavoriteIcon /> Love</Typo>
    <Typo>
      Hier bekommt Pani ein Bild pro Tag. Doch damit nicht genug: Pro Tag erhält sie hier auch einen Grund, warum Pan sie liebt. 
      <br/><br/>
      Wichtig ist hierbei zu wissen, dass kein Bild und Liebesgrund "verbraucht" wird, wenn Pani nicht in die App schaut.
      <br/><br/>
      Mindestens ebenso wichtig ist es zu ewähnen, dass die Liste an Gründen, Pani zu lieben, niemals vollständig sein wird. Es kommen quasi täglich neue hinzu.
    </Typo>

    <Typo variant="h2" sx={{ mt: 5 }}><PetsIcon /> Cats</Typo>
    <Typo>Pan liebt Pani. Pani liebt Katzen. Pan zeigt Pani Katzen! Hier kann sich Pani drei Katzen pro Tag anschauen und sich daran erfreuen.</Typo>
    <Typo variant="h2" sx={{ mt: 5 }}><QuestionAnswerIcon /> Weitere Fragen</Typo>
    <Typo>Bei weiteren Fragen wenden Sie sich bitte an ihren Pan. Dieser wird Ihnen schnellstmöglich weiterhelfen.</Typo>
    <Box textAlign="center">
      <img onClick={triggerAlert} className='doof-pan' src={panDoofImg} alt="pan doof" />
      <Typo><strong>Pani's Pan</strong></Typo>
    </Box>

  </main>
  )

}

export default Help;