import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PetsIcon from '@mui/icons-material/Pets';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';

import "./Navi.css";

const Navi = ({handleLogout}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("/");

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

     {/* VALUE: {value} */}
      <BottomNavigation
          sx={{backgroundColor: "#182225" }}
          showLabels
          value={value}
          onChange={(_, newValue) => {
            if(newValue === "logout") {
              return handleLogout();
            }
            setValue(newValue);
            navigate(newValue);
          }}
        >
          
          <BottomNavigationAction label="love" icon={<FavoriteIcon />} value="/" />
          <BottomNavigationAction label="cats" icon={<PetsIcon />} value="/cats"/>
          <BottomNavigationAction label="help" icon={<HelpIcon />} value="/help"/>
          <BottomNavigationAction label="logout" icon={<LogoutIcon />} value="logout"/>
          
        </BottomNavigation>
      </Paper>
  )

}

export default Navi;