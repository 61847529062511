import axios  from "axios";

const isDev = process.env.NODE_ENV === "development";
console.log("isDev", isDev);

const baseURLs = { 
  dev: "http://localhost:3000",
  prod: "https://pandpapp3000.onrender.com"
}

export default axios.create({
  baseURL: isDev ? baseURLs.dev : baseURLs.prod
});