import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Typo from '@mui/material/Typography';

const Loader = ({ isLoading, children, error }) => {
  const [showInfiniteLoader, setShowInfiniteLoader] = useState(false);
  if (isLoading) {
    return (
      <Box textAlign='center' display="flex" flexDirection="column" alignItems="center" sx={{ mt: 8 }} alignContent={true}>
        {showInfiniteLoader? 
          <>
            <CircularProgress color="secondary" />
          </>
          : 
          <CountdownCircleTimer
            isPlaying
            duration={50}
            colors={['#F13763', '#F13763', '#F13763', '#F13763']}
            // colorsTime={[7, 5, 2, 0]}
            size={70}
            strokeWidth={6}
            onComplete={() => setShowInfiniteLoader(true)}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
         }
        <br />
        <Typo sx={{ mt: 1 }} className='fadeInOutAnimation' variant="button">
         {!showInfiniteLoader ?
          "Lade - das kann momentan mehr als 50 Sekunden dauern...":
          "Hab noch etwas Geduld, liebe Pani."
          }
          
          </Typo>
      </Box>
    )
  } else if (error) {
    console.log("error in Loader.js:", error)
    // debugger;
    if (error?.response?.status === 401) {
      if (!error?.config?.url.includes("/auth")) { // error did not occur due to wrong login data (password)
        window.location.href = "/";
        return <Typo variant="body1" sx={{ mb: 5 }}>Du bist nicht authorisiert</Typo>;
      }
      return (
        <>
          <Typo variant="body1" sx={{ mb: 5 }}>Das supergeheime Passwort ist nicht korrekt! Versuche es noch einmal.</Typo>
          {children}
        </>
      )
    }
    return (<>
      <Typo variant="h2" sx={{ mb: 5, mt: 10 }}>Leider gab es einen Fehler. Pan sollte umgehend informiert werden.</Typo>
      <Typo variant="body1" sx={{ mb: 5, mt: 3 }}>Fehler-Meldung: "{error.message}"</Typo>
    </>);
  } else {
    return children;
  }
}

export default Loader;