import { useRef, useState, useEffect } from "react";
import apiAxios from '../util/apiAxios.js';
import Loader from "../components/Loader";
// import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import Typo from '@mui/material/Typography';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';




const Login = (props) => {

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const formEl = useRef(null)


  const submitHandler = async (e) => {
    e.preventDefault();
    
    const data = {
      password: e.currentTarget.elements.password.value
    }
    
    try {
      setIsLoading(true);
      const axiosResp = await apiAxios.post("/auth/login", data);
      console.debug("axiosResp.data:", axiosResp.data);
      setIsLoading(false);

      setError(""); // Falls wir zuvor einen Fehler hatten, wird dieser entfernt
      props.handleSuccessfullLogin(axiosResp.data);
    } catch (error) {
      console.error("Error while sending with axios", error);
      setIsLoading(false);
      setError(error);
      return;
    }
    
    // formEl.current.reset(); // Alle Felder vom Formular leer machen
  }

  const toggleShowPwd = () => {
    setShowPwd( current => !current)
  }

  useEffect(() => {
    document.body.classList.remove('claw-image');
    document.body.classList.remove('hearts-image');
  }, []);

  return (
    <main>
    <Typo variant="h1" gutterBottom>P.Papp 3000</Typo>
    <Typo variant="h2" sx={{mb:5}}>Die Pani&Pan App für Power-Pani</Typo>
    <Loader isLoading={isLoading} error={error}>
      <form 
          ref={formEl} 
          method="post"
          action='/auth/login'
          style={ false ? {display: 'none'} : null}
          onSubmit={submitHandler}
        >

          <TextField 
            name="password" 
            type={showPwd?"text":"password"} 
            label="Passwort" 
            variant="standard" 
          />
          
          <Box onClick={ toggleShowPwd } color="secondary" component="span" sx={{ display: 'inline-block',  mt: 2, ml: 2  }}>
            {showPwd ? <VisibilityOffIcon color="secondary" /> :<RemoveRedEyeIcon color="secondary" />}
          </Box>
          <br />
          <br />

          <Button color="secondary" type="submit" variant="contained">Login</Button>
          <Typo sx={{mt:5 }} variant="body1" >
            Liebe für Pani, jeden Tag – aber nur mit dem richtigen Passwort.
          </Typo>

          <br /> <br />
          {/* <Typo sx={{ display: 'inline-block',  mt: 20, ml: 2  }} variant="body1" gutterBottom>!!!TODO: Passwort NOCH ENTFERNEN aus defaultValue!</Typo> */}
      </form>
    </Loader>
    {/* <h2>Cat of the day</h2> */}
  </main>
  )

}

export default Login;