import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Navi from "./components/Navi/Navi.js";
import { isExpired, decodeToken } from "react-jwt";
import Container from '@mui/material/Container';

import Love from "./pages/Love.js";
import Login from "./pages/Login.js";
import placeholderImg from "./assets/images/placeholder.jpg";
import './App.css';
import Cats from "./pages/Cats.js";
import Help from "./pages/Help.js"

function App() {

  const [dailyMsgInfos, setDailyMsgInfos] = useState({});
  const [mainImage, setMainImage] = useState(placeholderImg)
  const [catImageUrl, setCatImageUrl] = useState("")
  const [initCatLoad, setInitCatLoad] = useState(false); 
  const [catCount, setCatCount] = useState(0)




  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  const handleSuccessfullLogin = (respData) => {
    setIsAuth(true);
    const decodedToken = decodeToken(respData.jwt); // respData.jwt: token, der vom Server geschickt wurde

    // JWT und Name im localStorage speichern
    // localStorage-Eintröge bleiben bestehen, wenn man browser schließt und wieder öffnet
    localStorage.setItem("jwt", respData.jwt);
    localStorage.setItem("name", decodedToken.userName )

    // Hier könnte man noch eine Auto-Logout Funktion aufrufen, die nach Ablauf der Gültigkeitsdauer
    // des Token den Nutzer automatisch ausloggt
    // initAutoLogout(decodedToken);
  }

  const hasClientUnexpiredToken = () => {
    // ist Token vorhanden? 
    const token  = localStorage.getItem("jwt");
    if(!token) return false;
    
    // ist Token abgelaufen?
    return !isExpired(token);
  }

  const handleLogout = () => {
    setIsAuth(false);
    localStorage.clear(); // löscht gesamten LocalStorage
    // localStorage.removeItem("jwt") // so könnte man einzelne Werte löschen
  }

  useEffect(()=>{
    if( hasClientUnexpiredToken() ) {
      setIsAuth(true)
      // initAutoLogout();
    }
  }, [])

  return (
    <>
      <header className="App-header">
      </header>

      <Container maxWidth="sm" className="App">
        {isAuth? 
          <Routes>
            <Route path="/" element={
              <Love 
                error={error}
                isLoading={isLoading}
                dailyMsgInfos= {dailyMsgInfos}
                mainImage={mainImage}
                setError={setError}
                setIsLoading={setIsLoading}
                setDailyMsgInfos={setDailyMsgInfos}
                setMainImage={setMainImage}
              />
            } />
            <Route path="/cats" element={
              <Cats 
                setCatImageUrl={setCatImageUrl}
                catImageUrl={catImageUrl}
                initCatLoad={initCatLoad}
                setInitCatLoad={setInitCatLoad}
                catCount={catCount}
                setCatCount={setCatCount}
              />} 
            />
            {/* <Route path="" element={} /> */}
            <Route path="/help" element={<Help />} />
            <Route path="*" element={<p>Huch, Seite nicht gefunden</p>} />

          </Routes>
          : 
          <Login handleSuccessfullLogin={handleSuccessfullLogin} />
        }
      </Container>
      {isAuth && 
        <footer>
          <Navi handleLogout={handleLogout} />
        </footer>
      }
    </>

  );
}


export default App;
