import { useState, useEffect } from "react";
import axios from 'axios';
import apiAxios from "../util/apiAxios";
import Loader from "../components/Loader";
import Typo from '@mui/material/Typography';
import { Box, Button } from "@mui/material";

const MAX_CATS = 3;
const Cats = (props) => {
  // TODO
  // Alles in <Loading> wrappen, außer das image
  const catsLeft = MAX_CATS - props.catCount;

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getAndSetCat = async (options) => {
    const authHeader = {
      authorization: `Bearer ${localStorage.getItem("jwt")}`
    };


    try {
      setIsLoading(true);
      const respCatCount = await apiAxios.get("/cat-count-today");
      // debugger;

      if (respCatCount.data.count >= MAX_CATS) {
        setIsLoading(false);
        props.setCatCount(respCatCount.data.count);
        props.setCatImageUrl(respCatCount.data.lastCatImage);
        return false;

      }
      const respCatImageApi = await axios.get("https://api.thecatapi.com/v1/images/search");

      // increment cat count and set cat image
      const respCatInc = await apiAxios.post("/inc-cat-count", {
        lastCatImage: respCatImageApi.data[0].url
      }, {
        headers: authHeader
      });
      props.setCatCount(respCatInc.data.count);
      props.setCatImageUrl(respCatImageApi.data[0].url);
      // debugger;
      // setIsLoading(false);

    } catch (error) {
      console.error(error);
      setError(error);
    }
  }


  useEffect(() => {
    document.body.classList.remove('hearts-image');
    document.body.classList.add('claw-image');
  },[]);

  useEffect(() => {
    console.debug('useEffect of Cats fired');


    (async () => {
      if (props.catImageUrl) {
        setIsLoading(false);
      } else if (!props.initCatLoad) {
        props.setInitCatLoad(true)
        await getAndSetCat({ fromUseEffect: true });
      }
    })()

    // https://dmitripavlutin.com/react-usecallback/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initCatLoad]);

  return (
    <main className="">
      <Typo variant="h1">Pani ♡ Katzen</Typo>
      <Typo variant="h2">Drei Katzen pro Tag</Typo>

      <Loader isLoading={isLoading} error={error} />

      <img
        className='cat-image image-frame'
        style={isLoading ? { display: 'none' } : {}}
        src={props.catImageUrl}
        alt="Random Cat"
        onLoad={() => {
          setIsLoading(false);
        }}
        onChange={() => { alert("changed") }}
      />
      {!isLoading &&
        <Box textAlign='center' sx={{ mt: 2 }}>
          {props.catCount >= 3 ?
            <Typo sx={{ mt: 2 }}>(=◉ᆽ◉=) Genug Katzen für heute </Typo> 
            :
            <>
              <Button
                color="secondary"
                variant="contained"
                onClick={getAndSetCat}
              >
                Zeig mir noch eins!
              </Button>


              <Typo sx={{ mt: 4 }}>
                Noch {catsLeft === 1 ? "ein" : catsLeft} Katzenbild{catsLeft > 1 && "er"} für heute übrig
              </Typo>
            </>

          }
        </Box>}

      {/* </Loader> */}
      <br />
    </main>
  )

}

export default Cats;