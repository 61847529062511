import { useEffect } from "react";
import apiAxios from '../util/apiAxios.js';
import Loader from "../components/Loader";
import Typo from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Box from "@mui/system/Box";
import Alert from "@mui/material/Alert";
import { AlertTitle, Paper } from "@mui/material";


const Love = (props) => {

  // TODO 
  // - error and isLoading should be own state like in Cats.js
  // - isLoading should be false when image is completetly loaded line in Cats.js

  // const [dailyMsgInfos, setDailyMsgInfos] = useState({});
  // const [error, setError] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  // const [mainImage, setMainImage] = useState(placeholderImg)

  const setupData = async () => {

    const authHeader = {
      authorization: `Bearer ${localStorage.getItem("jwt")}`
    };

    try {

      // throw new Error("Testing Error Handling in Love.js: setupData()")
      const respMsg = await apiAxios.post("/get-daily-message", {}, {
        headers: authHeader
      });

      console.log("respMsg in Love.js, setupData():", respMsg)

      const respImage = await apiAxios.get(`/image/${respMsg.data.imageName}`, {
        headers: authHeader,
        responseType: 'arraybuffer'
      });
      // file to base64
      const base64Image = window.btoa(
        new Uint8Array(respImage.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )

      respMsg.data.currentTempCelsius = respMsg.data.currentTempCelsius.toString().replace(".", ",")
      console.debug("respData:", respMsg.data);
      props.setMainImage(`data:image/jpeg;base64, ${base64Image}`);

      props.setDailyMsgInfos(respMsg.data);
      props.setIsLoading(false);
    } catch (error) {
      console.error("Error in Love.js: ", error);
      if(error.response?.data) {
        props.setError(error.response.data);
      } else {
        props.setError(error);
      }
      props.setIsLoading(false);
    }
  }

  useEffect(() => {
    document.body.classList.remove('claw-image');
    document.body.classList.add('hearts-image');
  }, []);

  useEffect(() => {
    console.debug('useEffect fired');
   
    // console.warn("'dev mode': loading prevented in Love.js useEffect")
    // return;
    setupData();

    // https://dmitripavlutin.com/react-usecallback/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getTemperatureComment = (tempString) => {
    if (!tempString) return null;

    const temp = tempString.replace(",", ".");

    // debugger;
    if (temp < 3) return "Viel zu kalt für Pani!";
    if (temp < 4) return "Das gefällt Pani nicht.";
    if (temp < 6) return "Zu kalt für Pani!";
    if (temp < 10) return "Nicht warm genug für Pani!";
    if (temp < 15) return "Könnte ein gutes Stück wärmer sein für Pani!";
    if (temp < 20) return "Könnte wärmer sein für Pani.";
    if (temp < 25) return "Keine schlechte Temperatur für Pani.";
    if (temp < 30) return "Pani sollte also nicht frieren.";
    if (temp < 35) return "Zeit, nackt baden zu gehen!";

  }

  return (
    <main className="">
      <Typo variant="h1">Pan ♡ Pani</Typo>

      <Loader isLoading={props.isLoading} error={props.error}>
        <Typo variant="h2" sx={{ mb: 2 }}>Heute</Typo>
        {/* <Typo sx={{ lineHeight: "1.5em" }} variant="body1" gutterBottom> */}
        {/* Heute  */}
        <List className="list">
          <li><ListItemText> ist der <strong>{props.dailyMsgInfos.currentDate}</strong>.</ListItemText></li>
          <li><ListItemText>
            beträgt die aktuelle Temperatur in Berlin <strong>{props.dailyMsgInfos.currentTempCelsius} °C</strong>.
            {" " + getTemperatureComment(props.dailyMsgInfos.currentTempCelsius)}</ListItemText></li>

          <li><ListItemText> bleibt eine Sache unverändert ↓</ListItemText></li>
        </List>

        <Box textAlign="center">
          <Typo variant="h2" sx={{ mt: 1.5, mb:2 }}>Pan liebt Pani!</Typo>
        </Box>


        {/* <Typo sx={{ lineHeight: "1.5em", mb: 2 }}> Doch das allerwichtigste bleibt unverändert: </Typo> */}

        <img className='main-image image-frame' src={props.mainImage} alt="Pan und Pani" />
        <Box textAlign="center" sx={{mt:2, p:2}}><Typo>{props.dailyMsgInfos.panyProperty}</Typo></Box>
        {/* <Typo sx={{ lineHeight: "1.5em", mt: 2 }}> {props.dailyMsgInfos.panyProperty}</Typo> */}
        {/* <Box sx={{mt:20}} >Check </Box> */}
      </Loader>
    </main>
  )

}

export default Love;